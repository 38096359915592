@font-face {
  font-family: 'Maplestory';
  src: url('./fonts/Maplestory.otf') format('truetype');
}

* {
  font-family: 'Maplestory';
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
